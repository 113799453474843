
import React, { useEffect, useState } from 'react';
import Modal from '../../components/Modal';
import { useApi } from '../../api';
import { deleteBudgetFeeCategory, getBudgetFeeCategories, createBudgetFeeCategory, updateBudgetFeeCategory } from '../../redux/admin/budgetFeeCategoryActions';

import * as yup from "yup";
import { useForm } from 'react-hook-form';


const BudgetFeeCategoryForm = ({toggleModal, formBudgetFeeCategory}) => {

  const { watch, register, formState: {errors}, handleSubmit, getValues, setValue } = useForm({
  });

  const [result, loading, loaded, error, doFetch, setResult] = useApi();

  const onSubmit = async data => {
    
    let d = {
      budget_fee_category: {
        ...data
      }
    }

    if (formBudgetFeeCategory && formBudgetFeeCategory.id) {
      doFetch(updateBudgetFeeCategory, {payload: d});
    }
    else {
      doFetch(createBudgetFeeCategory, {payload: d});
    }

  }

  useEffect(() => {
    if (loaded) {
      toggleModal(true);
    }
  }, [loaded]);

  const [deleteModalOpened, setDeleteModalOpened] = useState(false);

  const toggleDeleteModal = () => {
    setDeleteModalOpened(o => !o);
  }

  const [deleteResult, deleteLoading, deleteLoaded, deleteError, doDeleteFetch, setDeleteResult] = useApi();
  
  const tryDeleteBudgetFeeCategory = () => {
    // alert('To be implemented.');
    // return 
    if (formBudgetFeeCategory) {
      const payload = {
        budget_fee_category: {
          id: formBudgetFeeCategory.id
        }
      }
      doDeleteFetch(deleteBudgetFeeCategory, {payload: payload});
    }
  }

  useEffect(() => {
    if (deleteLoaded) {
      toggleDeleteModal();
      toggleModal(true);
    }
  }, [deleteLoaded]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="modal-header flex-cont align-center">
        <h2 className="flex-1">{formBudgetFeeCategory ? "Edit" : "New"} Budget Fee Category</h2>
        <div onClick={toggleModal} className="ghost button close">
          <i className="fas fa-times"></i>
        </div>
      </div>

      <div className="padding-1">

       

        <div className="flex-cont">

          <input {...register("id")} type="hidden" name="id" value={formBudgetFeeCategory?.id} />


          <label htmlFor="name"  className="margin-right-half"> 
            <div style={{marginBottom: '4px'}} className="gray bold font-075 uppercase">Category Name</div>
            <input 
              type="text"
              {...register("name", {
                required: "Enter a name."
              })}
              placeholder="Name"
              name="name"
              defaultValue={formBudgetFeeCategory?.name}
              className={`${errors.name ? 'has-errors' : ''}`}
            />
            {errors.name && <div className="field-error">{errors.name.message}</div>}
          </label>
        </div>

      </div>

      <div className="modal-footer align-center flex-cont">
        
        <input type="submit" value="Save" className={`${loading ? 'disabled' : ''} button`} />

        {loading &&
          <div className="margin-left-1 spinner"></div>
        }

        <div className="flex-1"></div>
        <div onClick={toggleDeleteModal} className="button danger">Delete</div>
      </div>

      {deleteModalOpened &&
        <Modal size="small" toggleModal={toggleDeleteModal} focusTrap={false}>
          <div className="modal-header flex-cont align-center">
            <h2 className="flex-1">Delete Budget Fee Category</h2>
            <div onClick={toggleDeleteModal} className="ghost button close">
              <i className="fas fa-times"></i>
            </div>
          </div>

          <div className="padding-1">
            Are you sure you want to delete <span className="bold">{formBudgetFeeCategory?.name}</span>?
          </div>

          <div className="modal-footer align-center flex-cont">
            <div onClick={toggleDeleteModal} className="ghost button margin-right-1">Cancel</div>
            <div onClick={tryDeleteBudgetFeeCategory} className="danger button">
              {deleteLoading && <div className="spinner button-spinner"></div> }
              Delete
            </div>
          </div>
        </Modal>
      }

    </form>


  )
}



const AdminBudgetFeeCategories = ({children}) => {

  const [budgetFeeCategories, budgetFeeCategoriesLoading, budgetFeeCategoriesLoaded, budgetFeeCategoriesError, doBudgetFeeCategoriesFetch, setBudgetFeeCategoriesResult] = useApi();
  
  useEffect(() => {
    fetchBudgetFeeCategories();
  }, []);

  const fetchBudgetFeeCategories = () => {
    doBudgetFeeCategoriesFetch(getBudgetFeeCategories);
  }

  const [addOpened, setAddOpened] = useState(false);

  const [newName, setNewName] = useState("");


  const [newBudgetFeeCategory, newBudgetFeeCategoryLoading, newBudgetFeeCategoryLoaded, newBudgetFeeCategoryError, doNewBudgetFeeCategoryFetch, setNewBudgetFeeCategoryResult] = useApi();

  const trySaveBudgetFeeCategory = () => {
    const payload = {
      name: newName
    };
    doNewBudgetFeeCategoryFetch(createBudgetFeeCategory, {payload: payload});
  }

  useEffect(() => {
    if (newBudgetFeeCategoryLoaded) {
      setAddOpened(false);
      setNewName("");
      fetchBudgetFeeCategories();
    }
  }, [newBudgetFeeCategoryLoaded]);

  const [editModalOpened, setEditModalOpened] = useState(false);
  const [editingBudgetFeeCategory, setEditingBudgetFeeCategory] = useState(null);

  const toggleEditModal = (reload=false) => {
    setEditModalOpened(o => !o);

    if (reload) {
      fetchBudgetFeeCategories();
    }
  }

  const editBudgetFeeCategoryClicked = (budgetFeeCategory) => {
    
    setEditingBudgetFeeCategory(budgetFeeCategory);
    setEditModalOpened(true);
  }


  return (
    <>
      <div className="section margin-bottom-1">
        <h2 className="no-margin margin-bottom-1">Fee Categories</h2>

        {editModalOpened && toggleEditModal &&
          <Modal toggleModal={toggleEditModal}>
            <BudgetFeeCategoryForm formBudgetFeeCategory={editingBudgetFeeCategory} toggleModal={toggleEditModal}/>
          </Modal>
        }

        {budgetFeeCategoriesLoading && <div className="spinner"></div>}
        {true &&
          <table className="basic">
            {budgetFeeCategories?.map(budgetFeeCategory => 
              <tr>
                <td className="full">
                  {budgetFeeCategory.name}
           
                </td>
                <td>
                  {/* <i onClick={() => editBudgetFeeCategoryClicked(budgetFeeCategory)} className="circle button fas fa-pencil-alt"></i> */}
                  <button onClick={() => editBudgetFeeCategoryClicked(budgetFeeCategory)} className="font-08 ghost button">
                    <i className="fas fa-pencil-alt"></i>
                  </button>
                </td>
              </tr>
            )}
          </table>
        }

        <div className="margin-top-1">

          {addOpened &&
            <div className="flex-cont align-center">
              <input onChange={e => setNewName(e.target.value)} placeholder="New fee category" className="margin-right-1" type="text" name="test" id=""/>
              
              
              <div onClick={trySaveBudgetFeeCategory} className={`${newBudgetFeeCategoryLoading ? 'disabled' : ''} button margin-right-1`}>
                {newBudgetFeeCategoryLoading && <div className="button-spinner spinner"></div>}
                Add
              </div>
              <div onClick={() => setAddOpened(o => !o)} className="button circle">
                <i className="fas fa-times"></i>
              </div>
            </div>
          }

          {!addOpened &&
            <div onClick={() => setAddOpened(o => !o)} className="button ghost">
              <i className="fas fa-plus margin-right-half"></i>
              <span>Add Fee Category</span>
            </div>
          }

        </div>

        
      </div>
    </>
  )
}

export default AdminBudgetFeeCategories;



