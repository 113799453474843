import React, { useEffect, useState, useRef } from 'react';

// import TimesheetsNav from './TimesheetsNav';
import { useApi } from '../../api';
import { connect } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import * as yup from "yup";
import {lazy, string, object} from 'yup';
// import { getBudgetHeader } from '../../redux/manager/budgets/budgetActions';
import { getMyProjects, getProject } from '../../redux/projects/projectActions';
import { getProjectUsers, createProjectUser, addProjectUser, removeProjectUser, setProjectUsers, deleteProjectUser, updateProjectUser, changeProjectUser } from '../../redux/manager/project_users/projectUserActions';
import { getUsers } from '../../redux/manager/users/userActions';
import { DateTime } from 'luxon';
import {
  Link,
  useParams
} from 'react-router-dom';
import store from '../../redux/store';

import { saveBudgetHeader, clearBudgetHeader, addBudgetLineTask, getBudgetHeader, removeBudgetLineTask, createBudgetLineTask, deleteBudgetLineTask} from '../../redux/manager/budgetHeaders/budgetHeaderActions';
// import { addBudgetTask, removeBudgetTask } from '../../redux/manager/budgetTasks/budgetTaskActions';
import { addBudgetFee, removeBudgetFee } from '../../redux/manager/budgetFee/budgetFeeActions';
import { create } from 'lodash';
import BudgetTaskRow from './BudgetTaskRow';

import DatePicker from 'react-date-picker';
// import '../../styles/Calendar.scss';

const BudgetLineRow = ({clearErrors, currencySymbol, stats, balanceNegative, reset, updateValues, control, saveBudgetHeaderLoaded, getValues, setValue, readOnly, watch, budgetPhases, rrr, trigger, formState, errors, lineErrors, lineIndex, line, budgetHeader, register, unregister, taskIndex}) => {

  

  const [createdTask, createTaskLoading, createTaskLoaded, createTaskError, doCreateTaskFetch, setCreateTaskResult] = useApi();

  const addLineTaskClicked = () => {
    const payload = {
      id: null, 
      taskname: newTask, 
      budgetlineid: line.id,
      originalhours: originalHours,
      billingpct: pctBilling,
      hoursrequired: hoursReq,
      estcompleteddate: estDate,
      completeddate: completedDate,
    }

    doCreateTaskFetch(createBudgetLineTask, {payload: payload})
  }
  useEffect(() => {
    if (createTaskLoaded) {
      setNewTask('');
      setOriginalHours('');
      setHoursReq('');
      setEstDate('');
      setCompletedDate('');
      setPctBilling('');

      trigger();
    }
  }, [createTaskLoaded]);
  
  // useEffect(() => {
  //   
  //   // setUpdatedErrors(formState.errors);
  // }, [errors]);

  const [newTask, setNewTask] = useState('');
  const [originalHours, setOriginalHours] = useState('');
  const [hoursReq, setHoursReq] = useState('');
  const [estDate, setEstDate] = useState('');
  const [completedDate, setCompletedDate] = useState('');
  const [pctBilling, setPctBilling] = useState('');

  const [settledPhase, setSettledPhase] = useState(null);



  
  const [rows, setRows] = useState([]);
  const [removedIndexes, setRemovedIndexes] = useState([]);




  const fieldName = `budget_lines.${lineIndex}.phaseid`;

  const budgetLineTasks = line?.budget_line_tasks?.filter(task => task.budgetLineID === line.id);

  const [userCount, setUserCount] = useState(budgetLineTasks ? budgetLineTasks.length + 0 : 0);

  const isPending = budgetHeader?.budgetheaderstatusid?.toString() === "1";



  // useEffect(() => {
  //   // alert(line?.phaseid)
  //   setValue(`budget_lines.${lineIndex}.phaseid`, line?.phaseid)
  // }, [line])
  useEffect(() => {
    if (saveBudgetHeaderLoaded) {
      setRows([]);
      //setValue(`budget_lines.${lineIndex}.phaseid`, line?.phaseid)
      // reset({
      //   ...getValues(),
      //   [`budget_lines.${lineIndex}.phaseid`]: line?.phaseid,
      // });

    }
  }, [saveBudgetHeaderLoaded])


  useEffect(() => {
    if (saveBudgetHeaderLoaded && budgetHeader && line) {
 

      let tasks = line?.budget_line_tasks?.filter(task => task.budgetLineID === line.id);
      setUserCount(tasks ? tasks.length + 0 : 0)
      setRows([]);
      setRemovedIndexes([]);



      
      // setValue(`budget_lines.${lineIndex}.budget_line_tasks`, tasks)
      // setValue(`budget_lines.${lineIndex}.budget_line_tasks`, tasks)
      // reset({
      //   ...getValues(),
      //   [`budget_lines.${lineIndex}.budget_line_tasks`]: tasks
      // })
      // 
      // // setValue(`budget_lines.${lineIndex}.phaseid`, line?.phaseid)
      // reset({
      //   ...getValues(),
      //   [`budget_lines.${lineIndex}.phaseid`]: line?.phaseid,
      // });
    }
  }, [budgetHeader, saveBudgetHeaderLoaded])

  // const addRowClicked = () => {
  //   setRows(o => [...o, userCount+1]); 
  //   setUserCount(o => o+1);    
  // }

  const addRowClicked = () => {
    clearErrors(`budget_lines.${lineIndex}.id`)
    setRows(o => [...o, userCount]);
    setValue(`budget_lines.${lineIndex}.budget_line_tasks.${userCount}`, {})
    setUserCount(o => o+1);  
    
    
  }

  // const removeRowClicked = row => {
  //   setRows(o => [...o.filter(e => e !== row)] );
  // }

  // const removeExistingRowClicked = index => {
  //   setRemovedIndexes(prevRemovedIndexes => [...prevRemovedIndexes, index]);
  //   const fn = `team_users_attributes.${index}._destroy`;
  //   setValue(fn, true);
  // }
  
  const addTaskEnterPressed = e => {

    
    addRowClicked();
  }

 
 
  // const totalHoursConsumed = (budgetLineTasks?.reduce((a,b) => parseFloat(a) + parseFloat(b.hoursconsumed ? b.hoursconsumed : 0), 0));

  const validateAtLeastOneTask = () => {
    const lineValue = getValues(`budget_lines.${lineIndex}`);

      if (budgetHeader?.projectid != null && budgetHeader?.projectid?.charAt(0)?.toUpperCase() === "X") {
        return null;
      }

      if (!lineValue) return null;
      if (!lineValue.budget_line_tasks || lineValue.budget_line_tasks.length === 0) return "Please add a task for this milestone."
      
      const nonDestroyed = lineValue.budget_line_tasks.filter(task => task._destroy !== "1");

      if (nonDestroyed == null || nonDestroyed.length === 0) return "Please add a task for this milestone."

      return null;
  }

  return (
    <tr key={lineIndex}>
      <td className="width-phase">

        {/* <div onClick={() => 

        </div>
         */}
        {true &&
          <>
            <input disabled={readOnly}  {...register(`budget_lines.${lineIndex}.id`)} defaultValue={line.id} type="hidden" name={`budget_lines.${lineIndex}.id`} />
            
            {budgetPhases && budgetPhases.length > 0 && 

              <>
                {/* <div>
                  {line?.phaseid?.toString()}
                </div> */}
                {line && budgetPhases && budgetPhases.length > 0 && 
                  <select className={`${(readOnly) && 'disabled-input'} auto-width`} {...register(`budget_lines.${lineIndex}.phaseid`, {
                  required: 'Select a phase.'
                  })} defaultValue={line?.phaseid ? line?.phaseid?.toString() : ""} name={`budget_lines.${lineIndex}.phaseid`} id={`budget_lines.${lineIndex}.phaseid`}>
                    <option disabled value="">Select phase:</option>
                    {budgetPhases?.map(phase =>  {
                        return(<option key={phase.id}  value={phase.id?.toString()}>{phase.phasename}</option>);
                      }
                    )}
                  </select>
                }
                {!line &&
                  <select className={`${(readOnly) && 'disabled-input'} auto-width`} {...register(`budget_lines.${lineIndex}.phaseid`, {
                    required: 'Select a phase.'
                  })} defaultValue={""} name={`budget_lines.${lineIndex}.phaseid`} id="">
                    <option disabled value="">Select phase:</option>
                    {budgetPhases?.map(phase =>  {
                        return(<option key={phase.id}  value={phase.id?.toString()}>{phase.phasename}</option>);
                      }
                    )}
                  </select>
                }
                
                <div>
                  {errors?.budget_lines?.[lineIndex]?.phaseid &&
                    <div className="error-cont">{errors?.budget_lines?.[lineIndex]?.phaseid?.message}</div>
                  }
                </div>

              </>
            }
          </>
        }
      </td>

      <td className="width-milestone">
        <div className="gray font-075">
          {line.activityname}

          {/* <br />
          {line.proj_posted_v} */}
        </div>

        
        
        {/* <div className="gray font-075">
          Activity Number: {line.activitynum}
        </div>
        <div className="gray font-075">
          Contract Number: {line.contractnum}
        </div> */}
      </td>

      <td style={{padding: '0'}}  colSpan={18}>
      


      <table className="inner-table" style={{tableLayout: 'fixed'}}>
      {budgetLineTasks?.map((task, index) => 

        
        <tr className={`${removedIndexes.includes(index) ? 'hidden' : ''}`} key={task.id}>

          <BudgetTaskRow currencySymbol={currencySymbol} percentageBilling={line?.percentagebilling} projPosted={line?.proj_posted_v} stats={stats} balanceNegative={balanceNegative} milestoneBilled={line?.milestonebilled} milestonePaid={line?.milestonepaid} milestoneFee={line?.milestonefee} getValues={getValues} reset={reset} updateValues={updateValues} control={control} setRemovedIndexes={setRemovedIndexes} removedIndexes={removedIndexes} setValue={setValue} readOnly={readOnly} watch={watch} rrr={rrr} trigger={trigger}  taskErrors={lineErrors?.budget_line_tasks?.[index]} errors={errors} lineIndex={lineIndex} key={task.id} index={index} register={register} unregister={unregister} task={task} />
          
          

          {/* <div className="flex-cont align-center">
            <input disabled={createTaskLoading} value={newTask} onChange={e => setNewTask(e.target.value)} placeholder="New task" type="text" name="" id=""/>
            <div style={{width: '4em'}}>
              {createTaskLoading && <div className="margin-left-half font-05 spinner"></div> }
              {!createTaskLoading && <i onClick={addLineTaskClicked} className={`${newTask && newTask.length > 0 ? '' : 'disabled'} fas fa-plus-circle margin-left-half yellow icon-button`}></i>}
            </div>
            
          </div> */}
        </tr>

      )}


      {/* New Tasks */}

      {rows && rows.map((row, i) => {
        const ii = row;
        const taskFieldName = `budget_lines.${lineIndex}.budget_line_tasks.${ii}`;
        const taskErrors = errors?.budget_lines?.lineIndex?.budget_line_tasks?.[ii];


        return (
          <tr className={`${removedIndexes.includes(ii) ? 'hidden' : ''}`} key={ii}>

          <BudgetTaskRow currencySymbol={currencySymbol} percentageBilling={line?.percentagebilling} projPosted={line?.proj_posted_v} stats={stats}  balanceNegative={balanceNegative} milestoneBilled={line?.milestonebilled}  milestonePaid={line?.milestonepaid}  milestoneFee={line?.milestonefee} getValues={getValues} reset={reset} updateValues={updateValues} control={control} setRemovedIndexes={setRemovedIndexes} removedIndexes={removedIndexes} setValue={setValue} readOnly={readOnly} watch={watch} rrr={rrr} trigger={trigger}  taskErrors={taskErrors} errors={errors} lineIndex={lineIndex} key={taskFieldName} index={ii} register={register} unregister={unregister} task={null} />



          {/* <div className="flex-cont align-center">
            <input disabled={createTaskLoading} value={newTask} onChange={e => setNewTask(e.target.value)} placeholder="New task" type="text" name="" id=""/>
            <div style={{width: '4em'}}>
              {createTaskLoading && <div className="margin-left-half font-05 spinner"></div> }
              {!createTaskLoading && <i onClick={addLineTaskClicked} className={`${newTask && newTask.length > 0 ? '' : 'disabled'} fas fa-plus-circle margin-left-half yellow icon-button`}></i>}
            </div>
            
          </div> */}
          </tr>

        )
      })}
      {!readOnly &&
        <tr>
          <td>
            <div tabIndex={lineIndex} onKeyPress={e => addTaskEnterPressed(e)} onClick={addRowClicked} className="button ghost font-075">
              <i className="margin-right-half fas fa-plus font-075"></i>
              <div>Add Task</div>
            </div>
          </td>
        </tr>
      }
        



        
      </table>

      <div>
              {errors?.budget_lines?.[lineIndex]?.id &&
                <div className="error-cont">{errors?.budget_lines?.[lineIndex]?.id?.message}</div>
              }
            </div>
      </td>
        
        


    </tr>
    
  )
}


const mapState = state => ({
  budgetHeader: state.budgetHeaderState.data,
});
export default connect(mapState)(BudgetLineRow);
