import React from 'react';
import icon from '../images/logo_square_240.png';

const Loading = ({tokenLoading, fullscreenLoading, msalAccountLoading}) => {

  return(
    <div style={{paddingBottom: '5%'}} className="fullscreen-loading">

      {/* <div style={{marginBottom: '10em'}}>
        <pre>fullscreenLoading: {JSON.stringify(fullscreenLoading, null, 2)}</pre>
        <pre>accountLoading: {JSON.stringify(msalAccountLoading, null, 2)}</pre>
        <pre>tokenLoading: {JSON.stringify(tokenLoading, null, 2)}</pre>
      </div> */}
      <div className="spinner font-2">
        
      </div>
      {/* <img style={{position: 'absolute', width: '3em'}} src={icon} alt=""/> */}
      {/* <div>Loading...</div> */}

    </div>
  ); 
}

export default Loading;