import React, { useEffect, useState } from 'react';

import {
  BrowserRouter as Router,
  Routes,
  Route, NavLink,
  Link, Outlet, useLocation
} from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPeopleArrows } from '@fortawesome/free-solid-svg-icons'
import * as msal from "@azure/msal-browser";
import store from '../redux/store';
import { connect } from 'react-redux';
import { setTokenResponse } from '../redux/authActions';



const SideNav = ({currentUser, viewAsUser}) => {

  const [menuOpened, setMenuOpened] = useState(false); 

  const toggleMenu = () => {
    setMenuOpened(o => !o);
  }

  useEffect(() => {
    return () => {
      setMenuOpened(false);
    };
  }, []);

  const isAdmin = currentUser?.roleid === 2;

  const teamsPath = process.env.REACT_APP_DEPLOYMENT_SLOT === "prod" ? "/teams/project-manager" : "teams"

  if (!viewAsUser) return null;

  return (
    <>
      <div onClick={toggleMenu}  className="mobile-only burger">
        <i className="fas fa-bars"></i>
      </div>
      
      <div onClick={toggleMenu} className={`bottom-menu-bg ${menuOpened ? 'opened' : ''}`}></div>
      <nav className={`side-nav ${menuOpened ? 'opened' : ''} ${isAdmin ? 'admin-side-nav' : ''}`}>

        <ul>
          {/* <li>
            <Link to="/users">Users</Link>
          </li> */}

          <li>
            <Link exact to={`/`}>
              <i className="fas fa-home"></i>
              <span>Dashboard</span>
            </Link>
          </li>

          <li>
            <NavLink exact to={`/timesheets`}>
              <i className="fas fa-clock"></i>
              <span>Timesheets</span>
            </NavLink>
          </li>

          {viewAsUser && (viewAsUser.roleid === 4 || viewAsUser.roleid === 1 || viewAsUser.roleid === 8 || viewAsUser.roleid === 9 || viewAsUser.roleid === 2 || viewAsUser.roleid === 3) &&
            <li>
              <NavLink exact to={`/budgeting`}>
                <i className="fas fa-money-check-alt"></i>
                <span>Budgeting</span>
              </NavLink>
            </li>
          }
          {(viewAsUser.roleid !== 6) && <>
          
          
          <li className="apex">
            <div className="item-header">SETTINGS</div>

            <ul>
              {(viewAsUser.roleid !== 6 && process.env.REACT_APP_DEPLOYMENT_SLOT !== "prod") &&
                <>
                  {true && // viewAsUser && (viewAsUser.roleid === 1 || viewAsUser.roleid === 8 || viewAsUser.roleid === 9 || viewAsUser.roleid === 2 || viewAsUser.roleid === 4) &&
                  
                    <li>
                      <NavLink exact to={`/delegate-timesheets`}>
                        <i>
                          <FontAwesomeIcon icon={faPeopleArrows} />
                        </i>
                        
                        <span>Delegate</span>
                      </NavLink>
                    </li>
                  }
                </>
              }
              {(viewAsUser.roleid !== 6 && process.env.REACT_APP_DEPLOYMENT_SLOT === "prod") &&
                <>
                  {true && // viewAsUser && (viewAsUser.roleid === 1 || viewAsUser.roleid === 8 || viewAsUser.roleid === 9 || viewAsUser.roleid === 2 || viewAsUser.roleid === 4) &&
                  
                    <li>
                      <NavLink exact to={`/delegate-projects`}>
                        <i>
                          <FontAwesomeIcon icon={faPeopleArrows} />
                        </i>
                        
                        <span>Delegate</span>
                      </NavLink>
                    </li>
                  }
                </>
              }

        {viewAsUser && viewAsUser.roleid === 2 &&
            <>
              <li>
                <NavLink exact to={`/reports`}>
                  <i className="fas fa-file-alt"></i>
                  <span>Reports</span>
                </NavLink>
              </li>
              <li>
                <NavLink exact to={`/users`}>
                  <i className="fas fa-user"></i>
                  <span>Users</span>
                </NavLink>
              </li>
              <li>
                <NavLink exact to={`/admin-timesheets`}>
                  <i className="fas fa-business-time"></i>
                  <span>Manage Timesheets</span>
                </NavLink>
              </li>
              <li>
                <NavLink exact to={teamsPath}>
                  <i className="fas fa-users"></i>
                  <span>Teams</span>
                </NavLink>
              </li>
              <li>
                <NavLink to={`/logs/timesheets`}>
                  <i className="fas fa-list"></i>
                  <span>Logs</span>
                </NavLink>
              </li>
              <li>
                <NavLink exact to={`/support-tickets`}>
                  <i className="fas fa-question-circle"></i>
                  <span>Tickets</span>
                </NavLink>
              </li>
              <li>
              <NavLink exact to={`/settings`}>
                <i className="fas fa-cog"></i>
                <span>Settings</span>
              </NavLink>
            </li>
          
            </>
          }
          {viewAsUser && viewAsUser.roleid === 3 &&
            <>
              <li>
                <NavLink exact to={`/users`}>
                  <i className="fas fa-user"></i>
                  <span>Users</span>
                </NavLink>
              </li>
              <li>
                <NavLink exact to={teamsPath}>
                  <i className="fas fa-users"></i>
                  <span>Teams</span>
                </NavLink>
              </li>
              {/* <li>
                <NavLink exact to={`/settings`}>
                  <i className="fas fa-cog"></i>
                  <span>Settings</span>
                </NavLink>
              </li> */}
          
            </>
          }
          {viewAsUser && viewAsUser.roleid === 7 &&
            <>
              <li>
                <NavLink exact to={`/users`}>
                  <i className="fas fa-user"></i>
                  <span>Users</span>
                </NavLink>
              </li>          
            </>
          }
            </ul>
          </li>
          </>}

          

          
            
          


          {/* <li>
            <NavLink exact to={`/administrator`}>
              <i className="fas fa-lock"></i>
              <span>Administrator</span>
            </NavLink>
          </li> */}

          {/* <li>
            <NavLink exact to={`/settings`}>
              <i className="fas fa-cog"></i>
              <span>Settings</span>
            </NavLink>
          </li> */}

          {/* <li>
            <NavLink exact to={`/testing`}>
              <i className="fas fa-vial"></i>
              <span>TESTING PAGE</span>
            </NavLink>
          </li> */}


          {process.env.REACT_APP_DEPLOYMENT_SLOT !== "prod" &&
            <li className="apex">
              <div className="item-header">EXPENSES (TEST)</div>
              <ul>
                <li>
                  <NavLink exact to={`/expense-reports`}>
                    <i className="fas fa-file-invoice-dollar"></i>
                    <span>Expense Reports</span>
                  </NavLink>
                </li>
              </ul>


            </li>
          
          }

          {/* <li>
            <NavLink exact to={`/users`}>
              <i className="fas fa-life-ring"></i>
              <span>Support</span>
            </NavLink>
          </li> */}


          
        </ul>



        {currentUser && currentUser.roleid === 2 &&
          <div className="gray margin-left-1 font-075">
            v0.7.1-{process.env.REACT_APP_DEPLOYMENT_SLOT} 
          </div>
        }

        {/* <div className="code">
          <pre>
            {JSON.stringify(currentUser, null, 2)}
          </pre>
        </div> */}
        

      </nav>

     
    </>
  );
}

function mapState(state) {
  return { 
    // tokenResponse: state.authState.tokenResponse,
    currentUser: state.authState.currentUser,
    viewAsUser: state.authState.viewAsUser
  };
} 

export default connect(mapState)(SideNav);
