const initialState = {
  globalErrors: [],
  globalSuccesses: [],
  andyErrors: [],
  fullscreenLoading: true,
  fullscreenLoading2: false,
  changingCompany: false,
  test: 0,
};

const uiReducer = ( state = initialState, action) => {
  switch(action.type) {
    case "SET_FULLSCREEN_LOADING":
      return {
        ...state,
        fullscreenLoading: action.isLoading,
      }
    case "SET_FULLSCREEN_LOADING2":
      return {
        ...state,
        fullscreenLoading2: action.isLoading,
      }
      case "SET_CHANGING_COMPANY":
        return {
          ...state,
          changingCompany: action.isChanging,
        }
    case "ADD_GLOBAL_ERROR":
      return {
        ...state,
        globalErrors: [action.msg]
        // globalErrors: [...state.globalErrors, action.msg]
      }
    case "REMOVE_GLOBAL_ERROR":
      return {
        ...state,
        globalErrors: state.globalErrors.filter( (val, i) => i !== action.i )
      }
    case "CLEAR_GLOBAL_ERRORS":
      return {
        ...state,
        globalErrors: []
      }
    case "ADD_GLOBAL_SUCCESS":
      return {
        ...state,
        globalSuccesses: [...state.globalSuccesses, action.msg]
        // globalSuccesses: [...state.globalSuccesses, action.msg]
      }
    case "ADD_TEST":
      return {
        ...state,
        test: state.test + action.num
        // globalSuccesses: [...state.globalSuccesses, action.msg]
      }
    case "REMOVE_GLOBAL_SUCCESS":
      return {
        ...state,
        globalSuccesses: state.globalSuccesses.filter( (val, i) => i !== action.i )
      }
    case "CLEAR_GLOBAL_SUCCESSS":
      return {
        ...state,
        globalSuccesses: []
      }
    case "ADD_ANDY_ERROR":
      return {
        ...state,
        andyErrors: [...state.andyErrors, action.msg]
      }
    case "REMOVE_ANDY_ERROR":
      return {
        ...state,
        andyErrors: state.andyErrors.filter( (val, i) => i !== action.i )
      }
    default :
      return state
  }
}

export default uiReducer;