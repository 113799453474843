export const setFullscreenLoading = (isLoading) => {
  return {
    type: 'SET_FULLSCREEN_LOADING',
    isLoading
  }
}
export const setFullscreenLoading2 = (isLoading) => {
  return {
    type: 'SET_FULLSCREEN_LOADING2',
    isLoading
  }
}
export const setChangingCompany = (isChanging) => {
  return {
    type: 'SET_CHANGING_COMPANY',
    isChanging
  }
}



export const addError = (msg) => {

  
  return {
    type: 'ADD_GLOBAL_ERROR',
    msg
  }
}

export const removeError = (i) => {
  return {
    type: 'REMOVE_GLOBAL_ERROR',
    i
  }
}


export const addSuccess = (msg) => {
  // alert(msg);
  return {
    type: 'ADD_GLOBAL_SUCCESS',
    msg
  }
}
export const addTest = (num) => {
  // alert(msg);
  return {
    type: 'ADD_TEST',
    num
  }
}


export const removeSuccess = (i) => {
  return {
    type: 'REMOVE_GLOBAL_SUCCESS',
    i
  }
}


export const addAndyError = (msg) => {
  // alert(msg);
  return {
    type: 'ADD_ANDY_ERROR',
    msg
  }
}
export const removeAndyError = (i) => {
  return {
    type: 'REMOVE_ANDY_ERROR',
    i
  }
}