import React, { useEffect, useState } from 'react';
import { Document, Page, Text, View, StyleSheet, Font, Image } from '@react-pdf/renderer';
import { PDFViewer } from '@react-pdf/renderer';
import { connect } from 'react-redux';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link, Outlet, useLocation, useParams, useNavigate,
} from 'react-router-dom';
import { useApi } from '../../api';
import { getPrintProject } from '../../redux/projects/projectActions';
import { getEntries } from '../../redux/entries/entryActions';
import { entries } from 'lodash';
import { DateTime } from 'luxon';

import Modal from '../../components/Modal';
import logo from "../../images/logo1.png"
import { getLogoName } from '../../utils';



Font.register({
  family: 'Open Sans',
  fonts: [
    { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf' },
    { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf', fontWeight: 600 }
  ]
});

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: 'white',
    padding: 20,
    width: '100%',
    height: '100%',
  },
  text: {
    fontSize: 8,
    color: '#000000',
    fontFamily: 'Open Sans'
  },
  tableVertical: {
    display: 'flex',
    flexDirection: 'row',
    // border: '1 solid lime',
  },
  tableColumn: {
    display: 'flex',
    flexDirection: 'column',
    paddingRight: 12,
    // border: '1 solid red',
  },
  tableColumnFlex1: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    // border: '1 solid red',
  },
  thVerticalText: {
    fontWeight: 600,
    paddingRight: 12,
    fontFamily: 'Open Sans',
    minHeight: 10,
    // height: 12,
    // borderBottomColor: 'black',
    // borderBottomWidth: 1
  },
  tdVerticalText: {
    fontFamily: 'Open Sans',
    minHeight: 10,
    // height: 12,
    // borderBottomColor: 'black',
    // borderBottomWidth: 1
  },
  table: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  tr: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%'
  },
  tr: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  subtr: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    backgroundColor: 'pink'
  },
  td: {
    minHeight: 14,
    borderBottom: '1 solid gray',
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  noBorder: {
    borderBottom: '0 solid gray',
  },
  thNum: {
    width: 30,
    flex: 'auto'
  },
  tdNum: {
    width: 30,
    flex: 'auto'
  },
  th: {
    minHeight: 12,
    border: '1px solid black',
    flex: 1,
    borderRightColor: 'black',
    borderRightWidth: 2
  },
  f1: {
    flex: 1,
  },
  thText: {
    fontWeight: 600,
    fontSize: 6,
  },
  tdText: {

  },
  nowrap: {
    whiteSpace: 'nowrap'
  },
  image: {
    maxHeight: '30mm',
    maxWidth: '30mm'
  },
  logoCont: {
    alignItems: 'flex-start'
  },  
  tdNarrow: {
    minHeight: 10,
  },
  bold: {
    fontWeight: 600,
  },
  gray: {
    color: 'gray'
  },

});



// Create Document Component
const BudgetPrint = ({showPricing, pageSize, currentUser, project, result, entries, weeks, projectID}) => {

  const budgetHeader = result?.budget_header;
  const budgetLines = budgetHeader?.budget_lines;
  const projectPM = result?.project_pm;

  const [isReady, setIsReady] = useState(true);

  useEffect(() => {
    if (result && result.budgetHeader && result.projectPM) {
      setIsReady(true);
    }
    
  }, [result]);



  if (!isReady || !result || !budgetHeader || !projectPM) return <Document></Document>;

  return (
    <>
    {isReady && 
      <Document>
        <Page orientation="landscape" size={pageSize} style={styles.page}>

          <View  style={{display: 'flex', flexDirection: 'column', marginBottom: 10}}>
            <View style={{width: '100%', display: 'flex', flexDirection: 'row'}}>
              <View style={{flex: 1}}>

                <View style={styles.tableVertical}>
                  <View style={styles.tableColumn}>
                    <View style={{...styles.td, ...styles.tdNarrow, ...styles.noBorder, width: 100}}>
                      <Text style={{...styles.text, ...styles.thVerticalText}}>Project</Text>
                    </View>
                    <View style={{...styles.td, ...styles.noBorder,  ...styles.tdNarrow}}>
                      <Text style={{...styles.text, ...styles.thVerticalText}}>Project No.</Text>
                    </View>
                    <View style={{...styles.td, ...styles.noBorder,  ...styles.tdNarrow}}>
                      <Text style={{...styles.text, ...styles.thVerticalText}}>Manager</Text>
                    </View>
                    <View style={{...styles.td, ...styles.noBorder,  ...styles.tdNarrow}}>
                      <Text style={{...styles.text, ...styles.thVerticalText}}>Company</Text>
                    </View>
                    {showPricing &&
                      <View style={{...styles.td, ...styles.noBorder, ...styles.tdNarrow,}}>
                        <Text style={{...styles.text, ...styles.thVerticalText}}>Estimated Profit</Text>
                      </View>
                    }
                    <View style={{...styles.td, ...styles.noBorder, ...styles.tdNarrow,}}>
                      <Text style={{...styles.text, ...styles.thVerticalText}}>Project Start Date</Text>
                    </View>
                    <View style={{...styles.td, ...styles.noBorder, ...styles.tdNarrow,}}>
                      <Text style={{...styles.text, ...styles.thVerticalText}}>Target Completion</Text>
                    </View>
                    {showPricing &&
                      <View style={{...styles.td, ...styles.noBorder, ...styles.tdNarrow,}}>
                        <Text style={{...styles.text, ...styles.thVerticalText}}>Average Hourly Rate</Text>
                      </View>
                    }
                    {!showPricing &&
                      <View style={{...styles.td, ...styles.noBorder, ...styles.tdNarrow,}}>
                        <Text style={{...styles.text, ...styles.thVerticalText}}>
                          Hours Balance
                        </Text>
                      </View>
                    }
                  </View>

                  <View style={styles.tableColumn}>
                    <View style={{...styles.td, ...styles.noBorder, ...styles.tdNarrow}}>
                      <Text style={{...styles.text, ...styles.tdVerticalText}}>{projectPM.projectname}</Text>
                    </View>
                    <View style={{...styles.td, ...styles.noBorder, ...styles.tdNarrow,}}>
                      <Text style={{...styles.text, ...styles.tdVerticalText}}>{projectPM.projectid}</Text>
                    </View>
                    <View style={{...styles.td, ...styles.noBorder, ...styles.tdNarrow,}}>
                      <Text style={{...styles.text, ...styles.tdVerticalText}}>{projectPM.firstname} {projectPM.lastname}</Text>
                    </View>
                    <View style={{...styles.td, ...styles.noBorder, ...styles.tdNarrow,}}>
                      <Text style={{...styles.text, ...styles.tdVerticalText}}>{projectPM.dataareaid}</Text>
                    </View>
                    {showPricing &&
                      <View style={{...styles.td, ...styles.noBorder, ...styles.tdNarrow,}}>
                        <Text style={{...styles.text, ...styles.tdVerticalText}}>{budgetHeader.estprofitpct}%</Text>
                      </View>
                    }
                    <View style={{...styles.td, ...styles.noBorder, ...styles.tdNarrow,}}>
                      <Text style={{...styles.text, ...styles.tdVerticalText}}>
                        {projectPM.projectedstartdate ? DateTime.fromISO(projectPM.projectedstartdate).toLocaleString() : "-"}
                      </Text>
                    </View>
                    <View style={{...styles.td, ...styles.noBorder, ...styles.tdNarrow,}}>
                      <Text style={{...styles.text, ...styles.tdVerticalText}}>
                        {budgetHeader.targetdate ? DateTime.fromISO(budgetHeader.targetdate).toLocaleString() : "-"}
                      </Text>
                    </View>
                    {showPricing &&
                      <View style={{...styles.td, ...styles.noBorder, ...styles.tdNarrow,}}>
                        <Text style={{...styles.text, ...styles.tdVerticalText}}>
                          {(budgetHeader.avghourlyrate != null) ? parseFloat(budgetHeader.avghourlyrate).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : '-'}
                        </Text>
                      </View>
                    }
                    {!showPricing &&
                      <View style={{...styles.td, ...styles.noBorder, ...styles.tdNarrow,}}>
                        <Text style={{...styles.text, ...styles.tdVerticalText}}>
                          {(budgetHeader.hoursbalance != null) ? parseFloat(budgetHeader.hoursbalance).toLocaleString() : '-'}
                        </Text>
                      </View>
                    }
                  </View>

                  {showPricing &&

                    <View style={styles.tableColumn}>
                      <View style={{...styles.td, ...styles.tdNarrow, ...styles.noBorder, width: 100}}>
                        <Text style={{...styles.text, ...styles.thVerticalText}}>BWS Last Updated</Text>
                      </View>
                      <View style={{...styles.td, ...styles.noBorder,  ...styles.tdNarrow}}>
                        <Text style={{...styles.text, ...styles.thVerticalText}}>BWS Status</Text>
                      </View>
                      <View style={{...styles.td, ...styles.noBorder,  ...styles.tdNarrow}}>
                        <Text style={{...styles.text, ...styles.thVerticalText}}>Project Currency</Text>
                      </View>
                      <View style={{...styles.td, ...styles.noBorder,  ...styles.tdNarrow}}>
                        <Text style={{...styles.text, ...styles.thVerticalText}}>Project Fee</Text>
                      </View>
                      <View style={{...styles.td, ...styles.noBorder, ...styles.tdNarrow,}}>
                        <Text style={{...styles.text, ...styles.thVerticalText}}>Project Labor Cost</Text>
                      </View>
                      <View style={{...styles.td, ...styles.noBorder, ...styles.tdNarrow,}}>
                        <Text style={{...styles.text, ...styles.thVerticalText}}>Other Costs</Text>
                      </View>
                      <View style={{...styles.td, ...styles.noBorder, ...styles.tdNarrow,}}>
                        <Text style={{...styles.text, ...styles.thVerticalText}}>Estimated Profit</Text>
                      </View>
                      <View style={{...styles.td, ...styles.noBorder, ...styles.tdNarrow,}}>
                        <Text style={{...styles.text, ...styles.thVerticalText}}></Text>
                      </View>
                    </View>
                  } 
                  {!showPricing &&
                    <>
                      <View style={styles.tableColumn}>
                        <View style={{...styles.td, ...styles.tdNarrow, ...styles.noBorder, width: 100}}>
                          <Text style={{...styles.text, ...styles.thVerticalText}}></Text>
                        </View>
                      </View>
                      <View style={styles.tableColumn}>
                        <View style={{...styles.td, ...styles.tdNarrow, ...styles.noBorder, width: 100}}>
                          <Text style={{...styles.text, ...styles.thVerticalText}}></Text>
                        </View>
                      </View>
                    </>
                  }

                  {showPricing &&

                    <View style={styles.tableColumn}>
                      <View style={{...styles.td, ...styles.noBorder, ...styles.tdNarrow}}>
                        <Text style={{...styles.text, ...styles.tdVerticalText}}>
                          {budgetHeader.approveddate ? DateTime.fromISO(budgetHeader.approveddate).toLocaleString() : "-"}
                        </Text>
                      </View>
                      <View style={{...styles.td, ...styles.noBorder, ...styles.tdNarrow,}}>
                        <Text style={{...styles.text, ...styles.tdVerticalText}}>{budgetHeader.statusname?.toUpperCase()}</Text>
                      </View>
                      <View style={{...styles.td, ...styles.noBorder, ...styles.tdNarrow,}}>
                        <Text style={{...styles.text, ...styles.tdVerticalText}}>{projectPM.salescurrency}</Text>
                      </View>
                      <View style={{...styles.td, ...styles.noBorder, ...styles.tdNarrow,}}>
                        <Text style={{...styles.text, ...styles.tdVerticalText}}>
                          {/* {budgetHeader.project_fee} */}
                          {(budgetHeader.project_fee != null) ? parseFloat(budgetHeader.project_fee).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : '-'}
                        </Text>
                      </View>
                      <View style={{...styles.td, ...styles.noBorder, ...styles.tdNarrow,}}>
                        <Text style={{...styles.text, ...styles.tdVerticalText}}>
                          {/* {budgetHeader.designfees} */}
                          {(budgetHeader.designfees != null) ? parseFloat(budgetHeader.designfees).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : '-'}
                        </Text>
                      </View>
                      <View style={{...styles.td, ...styles.noBorder, ...styles.tdNarrow,}}>
                        <Text style={{...styles.text, ...styles.tdVerticalText}}>
                          {/* {budgetHeader.othercosts} */}
                          {(budgetHeader.othercosts != null) ? parseFloat(budgetHeader.othercosts).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : '-'}
                        </Text>
                      </View>
                      <View style={{...styles.td, ...styles.noBorder, ...styles.tdNarrow,}}>
                        <Text style={{...styles.text, ...styles.tdVerticalText}}>
                          {(budgetHeader.estimatedprofit != null) ? parseFloat(budgetHeader.estimatedprofit).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : '-'}
                        </Text>
                      </View>
                      <View style={{...styles.td, ...styles.noBorder, ...styles.tdNarrow,}}>
                        <Text style={{...styles.text, ...styles.thVerticalText}}></Text>
                      </View>
                    </View>
                  }
                  {showPricing &&
                    <View style={styles.tableColumn}>
                      <View style={{...styles.td, ...styles.tdNarrow, ...styles.noBorder, width: 100}}>
                        <Text style={{...styles.text, ...styles.thVerticalText}}>Project Profit</Text>
                      </View>
                      <View style={{...styles.td, ...styles.noBorder,  ...styles.tdNarrow}}>
                        <Text style={{...styles.text, ...styles.thVerticalText}}>Hours Balance</Text>
                      </View>
                      <View style={{...styles.td, ...styles.noBorder, ...styles.tdNarrow,}}>
                        <Text style={{...styles.text, ...styles.thVerticalText}}></Text>
                      </View>
                      <View style={{...styles.td, ...styles.noBorder, ...styles.tdNarrow,}}>
                        <Text style={{...styles.text, ...styles.thVerticalText}}></Text>
                      </View>
                      <View style={{...styles.td, ...styles.noBorder, ...styles.tdNarrow,}}>
                        <Text style={{...styles.text, ...styles.thVerticalText}}></Text>
                      </View>
                      <View style={{...styles.td, ...styles.noBorder, ...styles.tdNarrow,}}>
                        <Text style={{...styles.text, ...styles.thVerticalText}}></Text>
                      </View>
                      <View style={{...styles.td, ...styles.noBorder, ...styles.tdNarrow,}}>
                        <Text style={{...styles.text, ...styles.thVerticalText}}></Text>
                      </View>
                      <View style={{...styles.td, ...styles.noBorder, ...styles.tdNarrow,}}>
                        <Text style={{...styles.text, ...styles.thVerticalText}}></Text>
                      </View>
                    </View>
                  }
                  {showPricing &&   
                    <View style={styles.tableColumn}>
                      <View style={{...styles.td, ...styles.noBorder, ...styles.tdNarrow}}>
                        <Text style={{...styles.text, ...styles.tdVerticalText}}>

                          {(budgetHeader.projectbalance != null) ? parseFloat(budgetHeader.projectbalance).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : '-'}
                        </Text>
                      </View>
                      <View style={{...styles.td, ...styles.noBorder, ...styles.tdNarrow,}}>
                        <Text style={{...styles.text, ...styles.tdVerticalText}}>
                          {(budgetHeader.hoursbalance != null) ? parseFloat(budgetHeader.hoursbalance).toLocaleString() : '-'}
                        </Text>
                      </View>
                      <View style={{...styles.td, ...styles.noBorder, ...styles.tdNarrow,}}>
                        <Text style={{...styles.text, ...styles.thVerticalText}}></Text>
                      </View>
                      <View style={{...styles.td, ...styles.noBorder, ...styles.tdNarrow,}}>
                        <Text style={{...styles.text, ...styles.thVerticalText}}></Text>
                      </View>
                      <View style={{...styles.td, ...styles.noBorder, ...styles.tdNarrow,}}>
                        <Text style={{...styles.text, ...styles.thVerticalText}}></Text>
                      </View>
                      <View style={{...styles.td, ...styles.noBorder, ...styles.tdNarrow,}}>
                        <Text style={{...styles.text, ...styles.thVerticalText}}></Text>
                      </View>
                      <View style={{...styles.td, ...styles.noBorder, ...styles.tdNarrow,}}>
                        <Text style={{...styles.text, ...styles.thVerticalText}}></Text>
                      </View>
                      <View style={{...styles.td, ...styles.noBorder, ...styles.tdNarrow,}}>
                        <Text style={{...styles.text, ...styles.thVerticalText}}></Text>
                      </View>
                    </View>
                  }


                </View>
              </View>
              {/* <Text>{process.env.PUBLIC_URL + `/public/images/${projectPM.company}.png`}</Text> */}
              <View style={{...styles.logoCont}}>
                {/* <Text>{window.location.origin + `/public/images/${projectPM.company}.png`}</Text> */}
                {/* <Image style={{...styles.image}} src={logo}  /> */}
                {/* <Image style={{...styles.image}} src={`/${getLogo(projectPM.dataareaid)}.png`}  /> */}

                {getLogoName(projectPM.dataareaid) &&
                  <Image style={{...styles.image}} src={`/${getLogoName(projectPM.dataareaid)}.png`}  />
                }

                {/* <Image style={{...styles.image}} src={logo}  /> */}
                
                {/* <img src={require(`../../folder-path/${dynamic-filename}.png`).default} /> */}
              </View>
            </View>

            <View style={{borderBottomColor: 'black', borderBottomWidth: 1, marginTop: 20, flexDirection: 'row', padding: 1, backgroundColor: ''}}>
              <View style={{flexDirection: 'row', padding: 1, backgroundColor: ''}}>
                <View style={{flexDirection: 'row', flex: 'auto', marginRight: 5, padding: 1, backgroundColor: ''}}>
                  <View  style={{width: 45, padding: 1, backgroundColor: ''}}>
                    <Text style={{...styles.text, ...styles.thText}}>PHASE</Text>
                  </View>
                  <View  style={{width: 100, padding: 1, backgroundColor: ''}}>
                    <Text  style={{...styles.text, ...styles.thText}}>MILESTONE</Text>
                  </View>
                </View>
              </View>

             <View style={{flex: 1, padding: 1, backgroundColor: ''}}>
                <>
                  <View style={{flexDirection: 'row', flex: 'auto', marginRight: 5, padding: 1, backgroundColor: ''}}>
                    <View  style={{width: 100, padding: 1, backgroundColor: ''}}>
                      <Text style={{...styles.text, ...styles.thText}}>TASK</Text>
                    </View>
                    <View  style={{width: 45, padding: 1, backgroundColor: ''}}>
                      <Text  style={{...styles.text, ...styles.thText}}>HOURS</Text>
                      <Text  style={{...styles.text, ...styles.thText}}>BUDGETED</Text>
                    </View>
                    <View  style={{width: 45, padding: 1, backgroundColor: ''}}>
                      <Text  style={{...styles.text, ...styles.thText}}>HOURS</Text>
                      <Text  style={{...styles.text, ...styles.thText}}>CONSUMED</Text>
                    </View>
  
                    <View  style={{width: 45, padding: 1, backgroundColor: ''}}>
                      <Text  style={{...styles.text, ...styles.thText}}>HOURS</Text>
                      <Text  style={{...styles.text, ...styles.thText}}>PENDING</Text>
                    </View>
                    <View  style={{width: 45, padding: 1, backgroundColor: ''}}>
                      <Text  style={{...styles.text, ...styles.thText}}>HOURS</Text>
                      <Text  style={{...styles.text, ...styles.thText}}>BALANCE</Text>
                    </View>
                    <View  style={{width: 45, padding: 1, backgroundColor: ''}}>
                      <Text  style={{...styles.text, ...styles.thText}}>HOURS TO</Text>
                      <Text  style={{...styles.text, ...styles.thText}}>REVISE</Text>
                    </View>
                    <View  style={{width: 45, padding: 1, backgroundColor: ''}}>
                      <Text  style={{...styles.text, ...styles.thText}}>EST. COMP.</Text>
                      <Text  style={{...styles.text, ...styles.thText}}>DATE</Text>
                    </View>
                    <View  style={{width: 45, padding: 1, backgroundColor: ''}}>
                      <Text  style={{...styles.text, ...styles.thText}}>%</Text>
                      <Text  style={{...styles.text, ...styles.thText}}>BILLING</Text>
                    </View>
                    <View  style={{width: 45, padding: 1, backgroundColor: ''}}>
                      <Text  style={{...styles.text, ...styles.thText}}>DATE</Text>
                      <Text  style={{...styles.text, ...styles.thText}}>COMP.</Text>
                    </View>
                  </View>
                </>
                    </View>

                    {showPricing &&
                    <View style={{flexDirection: 'row', flex: 'auto', padding: 1, backgroundColor: ''}}>
                      <View  style={{width: 50, padding: 1, paddingRight: 2, backgroundColor: ''}}>
                        <Text style={{...styles.text, ...styles.thText}}>MILESTONE</Text>
                        <Text  style={{...styles.text, ...styles.thText}}>FEE</Text>
                      </View>
                      <View  style={{width: 50, padding: 1, paddingRight: 2, backgroundColor: ''}}>
                        <Text  style={{...styles.text, ...styles.thText}}>MILESTONE</Text>
                        <Text style={{...styles.text, ...styles.thText}}>BILLED</Text>
                      </View>
                      <View  style={{width: 50, padding: 1, paddingRight: 2, backgroundColor: ''}}>
                        <Text  style={{...styles.text, ...styles.thText}}>MILESTONE</Text>
                        <Text style={{...styles.text, ...styles.thText}}>POSTED</Text>
                      </View>
                    </View>}



                  </View>

            {budgetLines?.map(entry => {
              return (
                <>
                  <View style={{flexDirection: 'row', padding: 1, backgroundColor: ''}}>

                    <View style={{flexDirection: 'row', flex: 'auto', marginRight: 5, padding: 1, backgroundColor: ''}}>
                      <View  style={{width: 45, padding: 1, backgroundColor: ''}}>
                        <Text style={{...styles.text, }}>{entry.phasename}</Text>
                      </View>
                      <View  style={{width: 100, padding: 1, backgroundColor: ''}}>
                        <Text  style={{...styles.text, }}>{entry.activityname}</Text>
                      </View>
                    </View>

                    <View style={{flex: 1, padding: 1, backgroundColor: ''}}>
                      { entry?.budget_line_tasks?.map(task => {
                        return (
                          <>
                            <View style={{flexDirection: 'row', flex: 'auto', marginRight: 5, padding: 1, backgroundColor: ''}}>
                              <View  style={{width: 100, padding: 1, backgroundColor: ''}}>
                                <Text style={{...styles.text, }}>{task.taskname}</Text>
                              </View>
                              <View  style={{width: 45, padding: 1, backgroundColor: ''}}>
                                <Text  style={{...styles.text, }}>{task.originalhours?.toLocaleString()}</Text>
                              </View>
                              <View  style={{width: 45, padding: 1, backgroundColor: ''}}>
                                <Text  style={{...styles.text, }}>{task.finalhoursconsumed?.toLocaleString()}</Text>
                              </View>
                              <View  style={{width: 45, padding: 1, backgroundColor: ''}}>
                                <Text  style={{...styles.text, }}>{task.hourspending?.toLocaleString()}</Text>
                              </View>
                              <View  style={{width: 45, padding: 1, backgroundColor: ''}}>
                                <Text  style={{...styles.text, }}>{task.hoursbalance?.toLocaleString()}</Text>
                              </View>
                              <View  style={{width: 45, padding: 1, backgroundColor: ''}}>
                                <Text  style={{...styles.text, }}>{task.hoursrequired?.toLocaleString()}</Text>
                              </View>
                              <View  style={{width: 45, padding: 1, backgroundColor: ''}}>
                                <Text  style={{...styles.text, }}>{task.estcompleteddate ? DateTime.fromISO(task.estcompleteddate).toLocaleString() : "-"}</Text>
                              </View>
                              <View  style={{width: 45, padding: 1, backgroundColor: ''}}>
                                <Text  style={{...styles.text, }}>{task.billingpct}</Text>
                              </View>
                              <View  style={{width: 45, padding: 1, backgroundColor: ''}}>
                                <Text  style={{...styles.text, }}>{task.completeddate ? DateTime.fromISO(task.completeddate).toLocaleString() : "-"}</Text>
                              </View>
                            </View>
                          </>
                        )
                      })}
                    </View>

                    {showPricing &&
                    <View style={{flexDirection: 'row', flex: 'auto', padding: 1, backgroundColor: ''}}>
                      <View  style={{width: 50, padding: 1, paddingRight: 2, backgroundColor: ''}}>
                        <Text style={{...styles.text, }}>{(entry.milestonefee != null) ? parseFloat(entry.milestonefee ).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : '-'}</Text>
                      </View>
                      <View  style={{width: 50, padding: 1, paddingRight: 2, backgroundColor: ''}}>
                        <Text  style={{...styles.text, }}>{(entry.milestonebilled != null) ? parseFloat(entry.milestonebilled ).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : '-'}</Text>
                      </View>
                      <View  style={{width: 50, padding: 1, paddingRight: 2, backgroundColor: ''}}>
                        <Text  style={{...styles.text, }}>{(entry.proj_posted_v != null) ? parseFloat(entry.proj_posted_v ).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : '-'}</Text>
                      </View>
                    </View>}
                    

                  </View>

                </>
              )
            })}



            <View style={{marginTop: 10, }}>

              <View  style={{marginBottom: 5, }}>
                <Text style={{...styles.text, fontWeight: 600, fontSize: 10, marginTop: 10}}>Other Costs</Text>
              </View>
              

              <View style={{borderBottomColor: 'black', borderBottomWidth: 1, flexDirection: 'row', flex: 'auto', padding: 1, backgroundColor: ''}}>
                <View  style={{width: 45, padding: 1, backgroundColor: ''}}>
                  <Text style={{...styles.text, ...styles.thText}}>PHASE</Text>
                </View>
                <View  style={{width: 80, padding: 1, backgroundColor: ''}}>
                  <Text  style={{...styles.text, ...styles.thText}}>CATEGORY</Text>
                </View>
                <View  style={{width: 200, padding: 1, backgroundColor: ''}}>
                  <Text  style={{...styles.text, ...styles.thText}}>DESCRIPTION</Text>
                </View>

                {showPricing &&
                <View  style={{width: 45, padding: 1, backgroundColor: ''}}>
                  <Text  style={{...styles.text, ...styles.thText}}>AMOUNT</Text>
                </View>}
              </View>

              {budgetHeader?.budget_fees?.map(fee => 
                <View style={{flexDirection: 'row', flex: 'auto', padding: 1, backgroundColor: ''}}>
                  <View  style={{width: 45, padding: 1, backgroundColor: ''}}>
                    <Text style={{...styles.text}}>{fee.phasename} </Text>
                  </View>
                  <View  style={{width: 80, padding: 1, backgroundColor: ''}}>
                    <Text  style={{...styles.text}}>{fee.categoryname}</Text>
                  </View>
                  <View  style={{width: 200, padding: 1, backgroundColor: ''}}>
                    <Text  style={{...styles.text}}>{fee.description}</Text>
                  </View>
                  {showPricing &&
                  <View  style={{width: 45, padding: 1, backgroundColor: ''}}>
                    <Text  style={{...styles.text}}>{(fee.amount != null) ? parseFloat(fee.amount ).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : '-'}</Text>
                  </View>}
                </View>
              )}
            </View>

            <View style={{marginTop: 10, }}>
              <View  style={{marginBottom: 5, }}>
                <Text style={{...styles.text, fontWeight: 600, fontSize: 10, marginTop: 10}}>Intercompany Projects</Text>
              </View>

              <View style={{borderBottomColor: 'black', borderBottomWidth: 1, flexDirection: 'row', flex: 'auto', padding: 1, backgroundColor: ''}}>
                <View  style={{width: 80, padding: 1, backgroundColor: ''}}>
                  <Text style={{...styles.text, ...styles.thText}}>COY</Text>
                </View>
                <View  style={{width: 180, padding: 1, backgroundColor: ''}}>
                  <Text  style={{...styles.text, ...styles.thText}}>PROJECT</Text>
                </View>
                <View  style={{width: 80, padding: 1, backgroundColor: ''}}>
                  <Text  style={{...styles.text, ...styles.thText}}>HOURS PENDING</Text>
                </View>
                <View  style={{width: 80, padding: 1, backgroundColor: ''}}>
                  <Text  style={{...styles.text, ...styles.thText}}>POSTED HOURS</Text>
                </View>
                <View  style={{width: 80, padding: 1, backgroundColor: ''}}>
                  <Text  style={{...styles.text, ...styles.thText}}>TOTAL HOURS</Text>
                </View>
              </View>

              {budgetHeader?.inter_projects?.map(project => 
                <View style={{flexDirection: 'row', flex: 'auto', padding: 1, backgroundColor: ''}}>
                  <View  style={{width: 80, padding: 1, backgroundColor: ''}}>
                    <Text style={{...styles.text}}>{project.coy} </Text>
                  </View>
                  <View  style={{width: 180, padding: 1, backgroundColor: ''}}>
                    <Text  style={{...styles.text}}>{project.projectname}</Text>
                  </View>
                  <View  style={{width: 80, padding: 1, backgroundColor: ''}}>
                    <Text  style={{...styles.text}}>{(project.pending != null) ? parseFloat(project.pending ).toLocaleString() : '-'}</Text>
                  </View>
                  <View  style={{width: 80, padding: 1, backgroundColor: ''}}>
                    <Text  style={{...styles.text}}>{(project.posted != null) ? parseFloat(project.posted ).toLocaleString() : '-'}</Text>
                  </View>
                  <View  style={{width: 80, padding: 1, backgroundColor: ''}}>
                    <Text  style={{...styles.text}}>{(project.posted != null && project.pending != null) ? (parseFloat(project.posted) + parseFloat(project.pending)).toLocaleString() : '-'}</Text>
                  </View>
                </View>
              )}

              
            </View>

            <View style={{marginTop: 10, }}>
              <View  style={{marginBottom: 5, }}>
                <Text style={{...styles.text, fontWeight: 600, fontSize: 10, marginTop: 10}}>Team</Text>
              </View>

              <View style={{borderBottomColor: 'black', borderBottomWidth: 1, flexDirection: 'row', flex: 'auto', padding: 1, backgroundColor: ''}}>
                <View  style={{width: 60, padding: 1, backgroundColor: ''}}>
                  <Text style={{...styles.text, ...styles.thText}}>LIST</Text>
                </View>
                
              </View>

              
              <Text style={{...styles.text}}>
                {budgetHeader?.team?.team_users?.map((team_user, index) => 
                  <>
                    {team_user.userfname} {team_user.userlname}{(index+1 < budgetHeader?.team?.team_users?.length) ? ', ' : ' '} 
                  </>
                )}
              </Text>

              
            </View>

          
          </View>

       
        </Page>
      </Document>
    }
    </>
  );
}

const BudgetPrintViewer = ({toggleModal, opened, project, currentUser, projectID}) => {

  const [projectResult, projectLoading, projectLoaded, projectError, doProjectFetch, setProjectResult] = useApi();
  const [isReady, setIsReady] = useState(false);
  const [pageSize, setPageSize] = useState("A4");

  const [showPricing, setShowPricing] = useState(true);

  useEffect(() => {
    if (project || (projectID || projectID === 0) ) {
      fetchProject();
    }
  }, [project, projectID]);


  const fetchProject = () => {
    if (projectID !== null && projectID !== undefined) {
      doProjectFetch(getPrintProject, {query: {print: '1', id: projectID}});
    }
    else {
      doProjectFetch(getPrintProject, {query: {print: '1', id: project?.id}});
    }
    
  }

  useEffect(() => {
    if (projectResult) {
      setTimeout(()=>{
        setIsReady(true);
      }, 100)
    }
  }, [projectResult]);


  return (
    <>
    {/* <pre>
      projectResult: {JSON.stringify(projectResult, null, 2)}
    </pre> */}
    {!isReady &&
      <div className="full center">
        <div className="margin-4">
          <div className="spinner"></div>
        </div>
        
      </div>
      
    }
    {isReady && 
      <>

        {pageSize && opened &&
          <>

            
            <div className='modal-header flex-cont align-center'>
              <div className="bold font-1-5 flex-1c margin-right-2">Print Budget</div>

              <div className="margin-right-2 flex-1">
                <div onClick={() => setShowPricing(true)} className={`margin-right-1 button ${showPricing !== true ? 'ghost' : ''}`}>Show Costs</div>
                <div onClick={() => setShowPricing(false)} className={`button ${showPricing !== false ? 'ghost' : ''}`}>Hide Costs</div>
              </div>

              <div>
                <div onClick={() => setPageSize("A4")} className={`margin-right-1 button ${pageSize !== 'A4' ? 'ghost' : ''}`}>A4</div>
                <div onClick={() => setPageSize("Letter")} className={`button ${pageSize !== 'Letter' ? 'ghost' : ''}`}>Letter</div>
              </div>

              {/* <div onClick={toggleModal} className="ghost button close">
                <i className="fas fa-times"></i>
              </div> */}
            </div>

            <div style={{width: '100%', height: '100%'}}>
              {/* <div>
                {JSON.stringify(projectResult)}
              </div> */}
              <PDFViewer width='100%' height='100%' >
                <BudgetPrint showPricing={showPricing} weeks={projectResult?.weeks} pageSize={pageSize} entries={null} result={projectResult} project={projectResult?.project} currentUser={currentUser} />
              </PDFViewer>
            </div>
            
          </>
        }
      </>
    }
    </>
  );
};



function mapState(state) {
  return { 
    currentUser: state.authState.currentUser,
  };
} 

export default connect(mapState)(BudgetPrintViewer);