import React, { useEffect } from 'react';

import queryString from 'query-string'; 
import { changeCurrentCompany } from './redux/companies/companyActions';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link, Outlet, useLocation, useNavigate, useSearchParams
} from 'react-router-dom';

import store from './redux/store';
import { connect } from 'react-redux';
import { useApi } from './api';

import { setChangingCompany } from './redux/ui/uiActions';
import FullScreenSpinner from './components/FullScreenSpinner';

const ScrollToTop = ({changingCompany, viewAsUser, currentCompany}) => {
  const { pathname, search } = useLocation();
  const navigate = useNavigate("/");

  // const location = useLocation;
  // const [searchParams, setSearchParams] = useSearchParams();

  const [changeCompResult, changeCompLoading, changeCompLoaded, changeCompError, doChangeCompFetch, setChangeCompResult] = useApi();
  
  const changeCurrentCompanyClicked = company => {
    store.dispatch(setChangingCompany(true));
    doChangeCompFetch(changeCurrentCompany, {query: {company: company}})
  }

  useEffect(() => {
    if (changeCompLoaded || changeCompError) {

      // alert(JSON.stringify(changeCompLoaded, null, 2))
      store.dispatch(setChangingCompany(false));
    }
  }, [changeCompLoaded, changeCompError]);

  useEffect(() => {
    const parsed = queryString.parse(search);

    // alert(JSON.stringify(parsed, null, 2));

    if (parsed.company == null) {
      // navigate(`?company=${currentCompany}`, { replace: true });
    }
    else if (viewAsUser != null && parsed != null && parsed.company != null) {

      const company = parsed.company;
      const hasCompany = viewAsUser.my_companies.includes(company);

      if (hasCompany === false) {
        navigate(`?company=${currentCompany}`, { replace: true });
      } else if (company !== currentCompany) {
        changeCurrentCompanyClicked(company);
      }

    }
  }, [viewAsUser, search])

  useEffect(() => {
    
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      {/* <div style={{zIndex:99999999, position: 'fixed', width: '100vw', left: 0, top: 0, background: 'lime'}}>
        {JSON.stringify(changingCompany, null, 2)}
      </div> */}
      {changingCompany &&
        <FullScreenSpinner message="Loading company..." />
      }
    </>
  );
}

function mapState(state) {
  return { 
    viewAsUser: state.authState.viewAsUser,
    currentCompany: state.authState.currentCompany,
    // changingCompany: state.uiState.changingCompany, 
  };
} 

export default connect(mapState)(ScrollToTop);