

import React from 'react';

const FullScreenSpinner = ({message}) => {

  return (
    <div style={{
      backgroundColor: 'rgba(255,255,255,.7)',
      position: 'fixed',
      left: 0,
      top: 0,
      zIndex: 99999,
      pointerEvents: 'all',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      width: '100vw',
      height: '100vh',
      
      }}>
      <div className="spinner font-2"></div>
      <div className="Black center bold margin-top-1">
        {message}
      </div>
    </div>
  )
}

export default FullScreenSpinner;