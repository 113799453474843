import { andyFetch, actionReturn } from '../../api';
import { EmailClient, KnownEmailSendStatus } from '@azure/communication-email'

export const deleteEntry = (params) => {

  return async (dispatch, getState) => {

    const url = "timesheets_alpha/delete_entry";

    try {
      const response = await andyFetch(url, params, "POST");
      if (response.ok) {
        const json = await response.json();
        return actionReturn(json);

      } else {
        throw new Error('deleteEntry error.');
      }
    }
    catch (e) {

      
    }
  }
};


export const saveTimesheetAlpha = (params) => {

  return async (dispatch, getState) => {

    const url = "timesheets_alpha/save_timesheet";

    try {

      const response = await andyFetch(url, params, "POST");
      if (response.ok) {
        const json = await response.json();
        return actionReturn(json);

      } else {
        throw new Error('saveTimesheetAlpha error.');
      }

    }
    catch (e) {

    }
  }
};




const toHTML = noti => {
  if (!noti) return "";

  return(`
    <div>
      <p>
        Dear ${noti.first_name} ${noti.last_name},
      </p>
      <p>
        ${noti.submitter_name} is attempting to submit hours for <strong>${noti.projectid} - ${noti.projectname}</strong>, but the submitted hours would exceed this project's budget. Please review and adjust the budget at the link below so the team can continue logging their time.

        <ul>
          <li>Budgeted hours: ${noti.budgeted}</li>
          <li>Consumed hours: ${noti.consumed}</li>
        </ul>
      <p/>
      <p> 
        The link to this project's budget can be found here: <a href="${process.env.REACT_APP_FRONTEND_URL}/budgeting/projects/${noti.projectid}">${process.env.REACT_APP_FRONTEND_URL}/budgeting/projects/${noti.projectid}?company=${noti.company}</a>
      </p>

      <br />
      <br />

    </div>
      
  `)

}

export const trySendEmail = async (noti, email) => {
  const connectionString = "endpoint=https://kronoscommunicationservice.unitedstates.communication.azure.com/;accesskey=/vsK9rhTNvy1nkNE58uZJaMX7nnsvfmaw65tvRTKYCHV3IkgcK/sBYfw63cWYwlVrjMGNtk9NVY2uBbIqGbH/g==";

  const client = new EmailClient(connectionString);

  let subject = "";
  subject = `Employee cannot submit hours for ${noti.projectid} - ${noti.projectname}: Budget is 100% consumed.`

  // if (noti.emails != null && noti.emails.length != 1) {
  //   return;
  // }

  const tos = noti.emails.map(email => { return {address: email} });

  const emailMessage = {
      senderAddress: "DoNotReply@873963c4-576a-45ca-be3d-72bb48dd1f40.azurecomm.net",
      content: {
          subject: subject,
          plainText: `Dear ${noti.first_name} ${noti.last_name}, consumed hours for ${noti.projectid} has reached ${noti.percent}`,
          html: toHTML(noti)
      },
      recipients: {
          to: tos,
          bcc: [
            { address: "asimon@axseva.com" },
          ]
      },
  };
  

  let poller;

  try {
    poller = await client.beginSend(emailMessage);
  } catch (error) {

    console.log('error', error)
    // setError("An error occurred. Please try again.");
    // alert("An error occurred. Please try again.")
  }

  console.log('poller', poller)

  let result;

  try {
    result = await poller.pollUntilDone();
  } catch (error) {

    console.log('error2', error)
    // alert("An error occurred. Please try again.")
    // setError("An error occurred. Please try again.");
  }


  console.log('email result', result)
  // setError(null);
  // setLoading(false);
  // setLoaded(true);

}





export const submitTimesheetAlpha = (params) => {

  return async (dispatch, getState) => {

    const url = "timesheets_alpha/submit_timesheet";

    try {

      const response = await andyFetch(url, params, "POST");

  

      if (response.ok) {
        const json = await response.json();

        const fullJSON = actionReturn(json).fullJSON;
        const email_notis = fullJSON.email_notis;

        // if (process.env.REACT_APP_DEPLOYMENT_SLOT !== "prod") {
        //   if (email_notis != null && email_notis.length !== 0) {
        //     const noti = email_notis[0];
        //     trySendEmail(noti);
        //   }
        // }
        
        

        // alert(JSON.stringify(actionReturn(json), null, 2));


        return actionReturn(json);

      } else {

        // alert(JSON.stringify(response, null, 2))

        if (process.env.REACT_APP_DEPLOYMENT_SLOT !== "prod") {

          const email_notis = response.email_notis;


          if (email_notis != null && email_notis.length !== 0) {
            email_notis.forEach(noti => trySendEmail(noti))
          }
        }


        // const json = await response.json();
        
        // throw new Error('submitTimesheetAlpha error.');
      }

    }
    catch (e) {

    }
  }
};

export const saveEntryNote = (params) => {
  return async (dispatch, getState) => {
    const url = "timesheets_alpha/save_entry_note";
    try {
      const response = await andyFetch(url, params, "POST");
      if (response.ok) {
        const json = await response.json();
        return actionReturn(json);
      } else {
        throw new Error('saveEntryNote error.');
      }
    }
    catch (e) {
      
    }
  }
};


export const createEntry = (params) => {

  return async (dispatch, getState) => {

    const url = "timesheets_alpha/create_entry";

    try {
      const response = await andyFetch(url, params, "POST");
      if (response.ok) {
        const json = await response.json();
        return actionReturn(json);

      } else {
        throw new Error('createTimesheet error.');
      }
    }
    catch (e) {

      
    }
  }
};



export const getTimesheetAlpha = (params) => {
  return async (dispatch, getState) => {
    const url = "timesheets_alpha/show_timesheet";
    try {
      const response = await andyFetch(url, params, "GET");
      if (response.ok) {
        const json = await response.json();
        return actionReturn(json);

      } else {
        throw new Error('getTimesheet error.');
      }
    }
    catch (e) {
      
    }
  }
}

export const getProjectsAlpha = (params) => {
  return async (dispatch, getState) => {
    const url = "timesheets_alpha/get_projects";
    try {
      const response = await andyFetch(url, params, "GET");
      if (response.ok) {
        const json = await response.json();
        return actionReturn(json);

      } else {
        throw new Error('getProjectsAlpha error.');
      }
    }
    catch (e) {
      
    }
  }
}

export const getMilestonesAlpha = (params) => {
  return async (dispatch, getState) => {
    const url = "timesheets_alpha/get_milestones";
    try {
      const response = await andyFetch(url, params, "GET");
      if (response.ok) {
        const json = await response.json();
        return actionReturn(json);

      } else {
        throw new Error('getMilestonesAlpha error.');
      }
    }
    catch (e) {
      
    }
  }
}

export const getTasksAlpha = (params) => {
  return async (dispatch, getState) => {
    const url = "timesheets_alpha/get_tasks";
    try {
      const response = await andyFetch(url, params, "GET");
      if (response.ok) {
        const json = await response.json();
        return actionReturn(json);

      } else {
        throw new Error('getTasksAlpha error.');
      }
    }
    catch (e) {
      
    }
  }
}

export const getTimesheetSummaryAlpha = (params) => {
  return async (dispatch, getState) => {
    const url = "timesheets_alpha/summary";
    try {
      const response = await andyFetch(url, params, "GET");
      if (response.ok) {
        const json = await response.json();
        return actionReturn(json);

      } else {
        throw new Error('getTimesheetSummaryAlpha error.');
      }
    }
    catch (e) {
      
    }
  }
}

